<template>
  <main>
    <Information />
  </main>
</template>

<script>
import Information from "@/components/information/Information";
export default {
  name: "InformationView",
  components: {
    Information

  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_information";
</style>
